const BE_API_URL = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8080";
const categoryOptions = [
  'Salary', 'Thu Học Phí', 'Thu Nợ',
  'Ăn uống', 'Cà Phê, Trà Sữa,...', 'Đi chợ', 'Siêu Thị', 'Quần Áo', 'Giày Dép',
  'Tiền Điện', 'Phí Chung Cư', 'Tín Dụng', 'Hoá Đơn', 'Di Chuyển',
  'Gửi Xe', 'Xăng', 'Nhà Cửa', 'Bảo dưỡng', 'Vật nuôi', 'Sức Khoẻ',
  'Mỹ phẩm', 'Phụ Kiện', 'Thiết bị điện tử', 'Giải Trí', 'Du Lịch',
  'Cưới hỏi', 'Quà Tặng', 'Gửi nhà', 'Cho em', 'Con cái', 'Giáo Dục', 'Nuôi Con',
  'Đầu tư', 'Bảo Hiểm', 'Cắt Tóc', 'Tiền Điện Quê',
  'Trả Tiền Vay Nhà', 'Sinh Hoạt', 'Điện Thoại',
  'Trả Nợ', 'Phí DV Chung Cư', 'Internet',
  'Khác'
];
const options = []
categoryOptions.map((cate) => {
  options.push({ value: cate, label: cate.toUpperCase() })
})
const incomeCategoryOptions = ['Salary', 'Thu Học Phí', 'Thu Nợ']
const userOptions = [
  {value: 1, 'label': 'Derek'},
  {value: 2, 'label': 'Vui'},
]

const classCategories = [
//  { value: 1, label: 'Piano Kèm 1-1'},
 { value: 2, label: 'Luyện Thi ABRSM'},
 { value: 3, label: 'Ngắn Hạn (12 Buổi)', total_days: 12 },
 { value: 4, label: 'Dài Hạn (24 Buổi)',  total_days: 24 },
]
const teacherId = 1

const durationOptions = [
  { value: 45, label: '45m' },
  { value: 60, label: '1h' },
  { value: 90, label: '1h30m' }
]

const dayOfWeekOptions = [
  { value: 0, label: 'Monday' },
  { value: 1, label: 'Tuesday' },
  { value: 2, label: 'Wednesday' },
  { value: 3, label: 'Thursday' },
  { value: 4, label: 'Friday' },
  { value: 5, label: 'Saturday' },
  { value: 6, label: 'Sunday' }
]
const genderOptions = [
  { value: 0, label: 'Male' },
  { value: 1, label: 'Female' },
  { value: 3, label: 'Other' }
]

exports.BE_API_URL = BE_API_URL
exports.categoryOptions = options
exports.userOptions = userOptions
exports.incomeCategoryOptions = incomeCategoryOptions
exports.classCategories = classCategories
exports.teacherId = teacherId
exports.durationOptions = durationOptions
exports.dayOfWeekOptions = dayOfWeekOptions
exports.genderOptions = genderOptions
