import React, { useState, useEffect, CSSProperties } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlus, faFilter, faEye, faPen, faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'
import { FadeLoader } from 'react-spinners';
import { BE_API_URL, incomeCategoryOptions, categoryOptions } from '../constants';
import { notifyMessage } from '../common';
import { getCategories } from '../api'
const { format } = require('date-fns');
const bgImage = process.env.PUBLIC_URL + '/bg/2.jpg';
const override ={
  display: "block",
  margin: "50% auto",
};

const MoneyLover = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const toDay = new Date();
  const [startDate, setStartDate] = useState(toDay);
  const [endDate, setEndDate] = useState(toDay);
  const [transactions, setTransactions] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [totalOutcomeAmount, setTotalOutcomeAmount] = useState(0);
  const [totalIncomeAmount, setTotalIncomeAmount] = useState(0);
  const [displayValue, setDisplayValue] = useState('');
  const [showInputAmount, setShowInputAmount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultBgImage, setDefaultBgImage] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [currentUser, setCurrentUser] = useState(null)
  // const [categoryOptions, setCategoryOptions] = useState([]);

  const appendCharacter = (char) => {
    setDisplayValue((prevValue) => {
      // Check if the character is a digit or a decimal point
      if (/[0-9.]/.test(char)) {
        // Append the character to the current value
        const newValue = prevValue + char;
        // Format the number with commas
        return newValue;
      }
      // If the character is an operator or other symbol, just append it
      return prevValue + char;
    });
  };

  const clearDisplay = () => {
    setDisplayValue('');
    setAmount('');
  };

  const calculateResult = () => {
    try {
      const result = eval(displayValue);
      const formattedResult = numberToCurrency(result);
      setDisplayValue(formattedResult);
      setAmount(formattedResult);
      setShowInputAmount(false)
    } catch (error) {
      setDisplayValue('');
      setAmount('');
    }
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };
  const handleSelectUserChange = (option) => {
    setSelectedUserOption(option);
    console.log('Select user', option.value)
  }
  const handleSubmitTransaction = () => {
    const date = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`
    const formatedAmount = amount.replace(/,/g, '');
    const transactionType = incomeCategoryOptions.includes(selectedOption.value) ? 1 : 2
    fetch(`${BE_API_URL}/api/money-lover/create-transaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: formatedAmount, note, category: selectedOption.value, date,
        user_id: selectedUserOption.value, transaction_type: transactionType,
        user_name: currentUser
      })
    })
      .then(response => response.json())
      .then(data => {
        setAmount('')
        setDisplayValue('')
        setNote('')
        setSelectedOption(null)
        setSelectedDate(new Date())
        getTransactionsByDateRange()
        notifyMessage('success', 'Added success!')
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }
  const getTransactionsByDateRange = () => {
    if (endDate < startDate) {
      notifyMessage('error' ,'End date must be greater than start date')
      return
    }
    const startDateFortmatted = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
    const endDateFortmatted = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    fetch(`${BE_API_URL}/api/money-lover/get-transactions-by-date-range?startDate=${startDateFortmatted }&endDate=${endDateFortmatted}`)
      .then(response => response.json())
      .then(data => {
        let tempTotalOutcomeAmount = 0
        let tempTotalIncomeAmount = 0
        data.map((item) => {
          if (incomeCategoryOptions.includes(item.category)) {
            tempTotalIncomeAmount += item.amount
          } else {
            tempTotalOutcomeAmount += item.amount
          }
        })
        setTotalOutcomeAmount(tempTotalOutcomeAmount)
        setTotalIncomeAmount(tempTotalIncomeAmount)
        setTransactions(data)
        if (firstLoad) {
          setLoading(false)
          setDefaultBgImage(bgImage)
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }
  const selectThisWeek = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);
    setStartDate(start);
    setEndDate(end);
  }
  const selectThisMonth = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setStartDate(start);
    setEndDate(end);
  }
  const selectToday = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    setStartDate(start);
    setEndDate(end);
  }

  const selectYesterday = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    setStartDate(start);
    setEndDate(end);
  }

  // const handleOnchangeAmount = (event) => {
  //   let inputValue = event.target.value;
  //   // Remove non-numeric characters (except dots for decimals)
  //   inputValue = inputValue.replace(/[^0-9.]/g, '');

  //   // Convert the string to a number (parseFloat handles decimal numbers)
  //   let numericValue = parseFloat(inputValue);

  //   // Check if the input is a valid number
  //   if (!isNaN(numericValue)) {
  //     // Format the number as currency
  //     let formattedValue = numberToCurrency(numericValue);
  //     setAmount(formattedValue);
  //   } else {
  //     // Handle invalid input, maybe show an error message or setAmount('') to clear the input
  //     console.log('Invalid input');
  //     setAmount('');
  //   }
  // }

  const numberToCurrency = (amount) => {
    return amount.toLocaleString('en-US');
  };

  const handleFocusAmount = () => {
    setShowInputAmount(true)
    const newValue = amount.replace(/,/g, '');
    setDisplayValue(newValue);
  }

  const getUsersInGroup = (filter_user_uuid) => {
    fetch(`${BE_API_URL}/api/v1/getUsersInGroup`)
      .then(response => response.json())
      .then(data => {
        let opts = []
        data.users.map((u) => {
          opts.push({value: u.id, label: u.name})
        });
        setUserOptions(opts)
        opts = opts.filter((u) => u.value === filter_user_uuid);
        setSelectedUserOption(opts[0])
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  const hadleGetCategories = () => {
    // getCategories().then(categories => {
    //   setCategoryOptions(categories);
    // });
  }

  useEffect(() => {
    if (firstLoad) {
      const user = JSON.parse(localStorage.getItem('current_user'))
      setCurrentUser(user.id)
      getTransactionsByDateRange()
      getUsersInGroup(user.id)
      setFirstLoad(false)
      hadleGetCategories()
    }
    if (startDate && endDate) {
      getTransactionsByDateRange()
    }
  }, [firstLoad, startDate, endDate, totalIncomeAmount, totalOutcomeAmount])

  const renderMoal = (transaction) => {
    return (
      <Popup
        trigger={<button className="text-blue-500">
          <FontAwesomeIcon
            style={{ width: '18px' }}
            icon={faEye}
          />
        </button>}
        modal
        nested
      >
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header"> Transaction Detail </div>
            <div className="content">
              <p>Who: {renderLabel(transaction)}</p>
              <p>Amount: {transaction.amount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</p>
              <p>Note: {transaction.note}</p>
              <p>Category: {transaction.category}</p>
              <p>Date: {format(transaction.date, 'yyyy-MM-dd')}</p>
            </div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                close
              </button>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  const renderLabel = (transaction) => {
    // const user = userOptions.find(user => user.value === parseInt(transaction.user_id))
    const user = userOptions.find(user => user.value === transaction.user_uuid)
    return user ? user.label : null
  }

  return (
    <div>
      <ToastContainer />
      {/* {!loading && (
        <div className='w-full text-center'>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            MoneyLover
          </h1>
        </div>
      )} */}
      { loading && (<FadeLoader
        color='#f44e3b'
        loading={loading}
        cssOverride={override}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />) }
      {!loading && (
        // style={{ backgroundImage: `url(${defaultBgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
        <div>
          <div className="grid gap-4 grid-cols-2 p-4">
            <div>
              <h4 className='text-md font-semibold text-green-600'>Total Income</h4>
              <h3 className='text-xl font-bold text-green-600'>{totalIncomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</h3>
            </div>
            <div className='text-right'>
              <h4 className='text-md font-semibold text-red-600'>Total Outcome</h4>
              <h3 className='text-xl font-bold text-red-600'>{totalOutcomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</h3>
            </div>
          </div>
          <div className='p-4 text-center mt-16'>
            {showAddNew && (
              <button
                onClick={() => setShowAddNew(false)}
                className={`text-white bg-red-500 p-4 pl-5 pr-5 rounded-full hover:bg-red-700 focus:outline-none focus:shadow-outline-blue active:bg-red-800`}
              >
                <FontAwesomeIcon
                  icon={faClose}
                />
              </button>
            )}
            {!showAddNew && (
              <button
                onClick={() => setShowAddNew(true)}
                className={`text-white bg-blue-500 p-4 pl-5 pr-5 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800`}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                />
              </button>
            )}
          </div>
          <div className={`mt-4 ${showAddNew ? 'block' : 'hidden'}`}>
            <div className="grid gap-4 grid-cols-1 p-4">
              <div>
                <div class="calculator w-full">
                  <input
                    id="display"
                    value={displayValue}
                    readOnly
                    placeholder='0'
                    onFocus={handleFocusAmount}
                  />
                  { showInputAmount && (
                    <div className="buttons">
                      {[7, 8, 9, '/', 4, 5, 6, '*', 1, 2, 3, '-', 0, '.', '=', '+', 'C'].map((item) => (
                        <button key={item} onClick={() => {
                          if (item === '=') {
                            calculateResult();
                          } else if (item === 'C') {
                            clearDisplay();
                          } else {
                            appendCharacter(item.toString());
                          }
                        }}>
                          {item}
                        </button>
                      ))}
                    </div>
                  ) }
                </div>
                {/* <input value={amount} onChange={handleOnchangeAmount} type='text' className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Amount" /> */}
              </div>
            </div>
            <div className="grid gap-4 p-4">
              <div>
                <Select placeholder="Choose User" value={selectedUserOption} options={userOptions} onChange={handleSelectUserChange} />
              </div>
            </div>
            <div className='grid gap-4 grid-cols-1 p-4'>
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid gap-4 p-4">
              <div>
                <Select placeholder="Choose Category" value={selectedOption} options={categoryOptions} onChange={handleSelectChange} />
                <textarea
                  className="w-full mt-8 bg-gray-50 p-4 h-30 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your note here."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  maxLength={255}
                />
                <button
                    disabled={!selectedOption || !amount || !selectedDate || !selectedUserOption}
                    onClick={handleSubmitTransaction}
                    className={`mt-8 ${!selectedOption || !amount || !selectedDate || !selectedUserOption ? 'opacity-50' : ''} bg-blue-500 hover:bg-blue-700 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                  >
                    <FontAwesomeIcon
                      style={{ width: '18px' }}
                      icon={faPaperPlane}
                    /> {' '} Save
                  </button>
              </div>
            </div>
          </div>
          <div className={`${showAddNew ? 'hidden' : ''}`}>
            <div>
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <caption class="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  Transactions
                  <div className='grid grid-cols-3'>
                  <div className='pr-2'>
                    <DatePicker
                      selected={new Date(startDate)}
                      onChange={(date) => setStartDate(new Date(date))}
                      dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                      className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className='pl-2'>
                    <DatePicker
                      selected={new Date(endDate)}
                      onChange={(date) => setEndDate(new Date(date))}
                      dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                      className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className='pl-2'>
                      <button
                        onClick={() => getTransactionsByDateRange()}
                        className={`bg-green-500 hover:bg-green-700 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                      >
                        <FontAwesomeIcon
                          style={{ width: '18px' }}
                          icon={faFilter}
                        /> {' '} Filter
                      </button>
                  </div>
                  </div>
                  <div className='grid grid-cols-1 mt-4'>
                    <div>
                      <button
                        onClick={selectToday}
                        className={`bg-purple-500 hover:bg-purple-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                      >
                        Today
                      </button>
                      <button
                        onClick={selectYesterday}
                        className={`bg-yellow-500 hover:bg-yellow-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                      >
                        YTD
                      </button>
                      <button
                        onClick={selectThisWeek}
                        className={`bg-red-500 hover:bg-red-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                      >
                        This Week
                      </button>
                      <button
                        onClick={selectThisMonth}
                        className={`bg-blue-500 hover:bg-blue-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                      >
                        This Month
                      </button>
                      
                    </div>
                  </div>
                  {/* <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Browse a list of Flowbite products designed to help you work and play, stay organized, get answers, keep in touch, grow your business, and more.</p> */}
                </caption>
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">Amount</th>
                    <th scope="col" class="px-6 py-3">Date</th>
                    {/* <th scope="col" class="px-6 py-3">Category</th> */}
                    <th scope="col" class="px-6 py-3">Who?</th>
                    <th scope="col" class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={`transaction-${transaction.id}`} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class={`px-6 py-4 font-medium dark:text-white ${incomeCategoryOptions.includes(transaction.category) ? 'text-green-500' : 'text-red-500'}`}>
                        { incomeCategoryOptions.includes(transaction.category) ? "+" : "-" }
                        {transaction.amount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}
                      </th>
                      <td class="px-6 py-4">
                        {format(transaction.date, 'yyyy-MM-dd')}
                      </td>
                      {/* <td class="px-6 py-4">
                        {transaction.category}
                      </td> */}
                      <td class="px-6 py-4">
                        {renderLabel(transaction)}
                      </td>
                      <td class="">
                        <div className='grid grid-cols-2'>
                          <div>
                            {renderMoal(transaction)}
                          </div>
                          <div>
                            <Link to={`/money-lover-edit?id=${transaction.id}`} className='text-red-500'>
                              <FontAwesomeIcon
                                style={{ width: '18px' }}
                                icon={faPen}
                              />
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoneyLover;
