import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BE_API_URL, categoryOptions, incomeCategoryOptions } from '../constants';
import { notifyMessage } from '../common';

const MoneyLoverEdit= () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userOptions, setUserOptions] = useState([]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };
  const handleSelectUserChange = (option) => {
    setSelectedUserOption(option);
  }
  const handleSubmitTransaction = () => {
    const id = new URLSearchParams(window.location.search).get('id')
    const date = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`
    const formatedAmount = amount.replace(/,/g, '');
    const transactionType = incomeCategoryOptions.includes(selectedOption.value) ? 1 : 2
    fetch(`${BE_API_URL}/api/money-lover/update-transaction/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ amount: formatedAmount, note, category: selectedOption.value, date, user_id: selectedUserOption.value , transaction_type: transactionType })
    })
      .then(response => response.json())
      .then(data => {
        notifyMessage('success', 'Update transaction success!')
      })
      .catch(error => {
        console.error(error);
        notifyMessage('error', 'Update transaction failed!')
      });
  }

  const handleOnchangeAmount = (event) => {
    let inputValue = event.target.value;
    // Remove non-numeric characters (except dots for decimals)
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Convert the string to a number (parseFloat handles decimal numbers)
    let numericValue = parseFloat(inputValue);

    // Check if the input is a valid number
    if (!isNaN(numericValue)) {
      // Format the number as currency
      let formattedValue = numberToCurrency(numericValue);
      setAmount(formattedValue);
    } else {
      // Handle invalid input, maybe show an error message or setAmount('') to clear the input
      console.log('Invalid input');
      setAmount('');
    }
  }

  const numberToCurrency = (amount) => {
    return amount.toLocaleString('en-US');
  };

  const getTransactionDetail = (id, nuserOptions) => {
    fetch(`${BE_API_URL}/api/money-lover/get-transaction-detail?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        setSelectedOption({ value: data.category, label: data.category.toUpperCase() })
        setAmount(numberToCurrency(data.amount))
        setNote(data.note)
        setSelectedDate(new Date(data.date))
        const findUser =nuserOptions.find(user => user.value === data.user_uuid)
        setSelectedUserOption(findUser)
      } else {
        notifyMessage('error', 'Transaction not found')
      }
    }).catch(error => {
      notifyMessage('error', 'Transaction not found')
    });
  }
  const handleDeleteTransaction = () => {
    const id = new URLSearchParams(window.location.search).get('id')
    fetch(`${BE_API_URL}/api/money-lover/delete-transaction/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        notifyMessage('success', 'Delete transaction success!')
        window.location.href = '/money-lover'
      })
      .catch(error => {
        console.error(error);
        notifyMessage('error', 'Delete transaction failed!')
      });
  }

  const getUsersInGroup = (id) => {
    fetch(`${BE_API_URL}/api/v1/getUsersInGroup`)
      .then(response => response.json())
      .then(data => {
        let opts = []
        data.users.map((u) => {
          opts.push({value: u.id, label: u.name})
        });
        setUserOptions(opts)
        getTransactionDetail(id, opts)
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get('id')
    if (id && isFirstLoad) {
      getUsersInGroup(id)
      setIsFirstLoad(false)
    }
  }, [])
  return (
    <div>
      <ToastContainer />
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Edit Transaction
        </h1>
      </div>
      <div>
      <div className={`bg-gray-100 mt-4 pt-4`}>
          <Link to='/money-lover' className='text-blue-500 ml-4 font-bold'> Back to List</Link>
          <div className="grid gap-4 p-4">
            <div>
              <Select placeholder="Choose User" value={selectedUserOption} options={userOptions} onChange={handleSelectUserChange} />
            </div>
          </div>
          <div className="grid gap-4 grid-cols-2 p-4">
            <div>
              <input value={amount} onChange={handleOnchangeAmount} type='text' className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Amount" />
            </div>
            <div>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div className="grid gap-4  p-4">
            <div>
              <Select placeholder="Choose Category" value={selectedOption} options={categoryOptions} onChange={handleSelectChange} />
              <textarea
                className="w-full mt-8 bg-gray-50 p-4 h-40 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your note here."
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
               <button
                  disabled={!selectedOption || !amount || !selectedDate || !selectedUserOption}
                  onClick={handleSubmitTransaction}
                  className={`mt-8 ${!selectedOption || !amount || !selectedDate || !selectedUserOption ? 'opacity-50' : ''} bg-blue-500 hover:bg-blue-700 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                >
                  <FontAwesomeIcon
                    style={{ width: '18px' }}
                    icon={faPaperPlane}
                  /> {' '} Update
                </button>
                <button
                  onClick={handleDeleteTransaction}
                  className={`mt-8 ml-8 bg-red-500 hover:bg-red-700 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                >
                  <FontAwesomeIcon
                    style={{ width: '18px' }}
                    icon={faTrash}
                  /> {' '} Delete
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneyLoverEdit;
